import styled from 'styled-components';

export const LI = styled.li`
  margin-top: 0;
  margin-bottom: 16px;
  font-family: ${({ theme }) => theme.fonts.sharpSansMedium}, sans-serif;
  font-size: 14px;
  line-height: 1.4;

  a {
    font-weight: bold;
    color: black;
  }
`;
