import React from 'react';
import styled from 'styled-components';
import { H1, P } from '../components/page-elements';

const NotFoundPage = () => (
  <StyledNotFoundPage>
    <H1>NOT FOUND</H1>
    <P>You just hit a route that doesn&#39;t exist... the sadness.</P>
  </StyledNotFoundPage>
);

const StyledNotFoundPage = styled.div``;

export default NotFoundPage;
